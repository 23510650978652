import React from 'react';
import ThumbsUp from "../../Assets/images/thumbs-up.png";

const FeedbackSuccess = (props) => {
    return (
        <div className="feedback-success-modal">
            <div className="modal fade show" id="" tabIndex="-1" aria-labelledby="proSaveModalLabel"
                 aria-hidden="true" style={{display:"block"}}>
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0">
                                    <img src={ThumbsUp} alt="Save Notification Pro" width={"200px"}/>
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center-off text-lg-center mb-4"></h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">Thanks, we've sent your feedback to the audIT.app Team.</h3>
                                    <p className="color-05 fs-12 fw-300">
                                        This message will automatically close in 5 seconds.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackSuccess;