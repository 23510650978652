import React from "react";
import ThumbsUp from "../../Assets/images/thumbs-up.png";
import { APP_CONSTANTS } from "../../constants";
import moment from "moment";

const SaveModal = (props) => {
    const { id, h2, h2_sub, h3, p, nextSchedule } = props;
    return (
        <div className="save-modal custom_modal_backdrop">
            <div
                className="modal fade show"
                id={`${"SaveModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="SaveModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div
                            className={
                                id === "save" || id === "schedule" || id === "update"
                                    ? "modal-body px-5 py-4"
                                    : "modal-body"
                            }
                        >
                            <div className="row">
                                <div
                                    className={
                                        id === "save" || id === "schedule" || id === "update"
                                            ? "col-12 col-lg-4 text-center text-lg-left"
                                            : "col-12 col-lg-4 pl-0"
                                    }
                                >
                                    {id === "save" || id === "schedule" || id === "update" ? (
                                        <i
                                            className="far fa-calendar-check"
                                            style={{ fontSize: "200px", color: "#707070" }}
                                        ></i>
                                    ) : (
                                        <img
                                            src={ThumbsUp}
                                            alt="Save Notification Pro"
                                            width={"200px"}
                                        />
                                    )}
                                </div>
                                <div className="col-12 col-lg-8 px-4 pt-5 pt-lg-0">
                                    <h2
                                        className={`color-03 fs-24 fw-600 ${id === "schedule"
                                                ? "text-lg-left mb-0"
                                                : id === "update" || id === "save"
                                                    ? "text-lg-left mb-4"
                                                    : " text-lg-left mb-4"
                                            }`}
                                    >
                                        {h2}
                                    </h2>
                                    {id === "schedule" && (
                                        <h2 className="color-03 fs-24 fw-600  mb-4">
                                            {h2_sub}
                                        </h2>
                                    )}
                                    {id === "schedule" ? (
                                        <h3 className="color-05 fs-18 fw-600 mb-4">
                                            {`${h3}` +
                                                " " +
                                                `${moment(nextSchedule).format(
                                                    APP_CONSTANTS.DATE_FORMAT
                                                )}`}
                                        </h3>
                                    ) : (
                                        <h3 className="color-05 fs-18 fw-600 mb-4">{h3}</h3>
                                    )}

                                    <p className="color-05 fs-12 fw-300">
                                        {id === "parentGmpInspection" ? (
                                            "This message will automatically close in 5 seconds and you'll be magically transported To-do list tab!"
                                        ) : (
                                            <>{p}</>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="modal-backdrop fade show"></div> */}
        </div>
    );
};

export default SaveModal;
