import BrowseMagnifier from "../../Assets/images/magnifier.png";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
// import { setSelectedAction } from "../../store/actions/action";
// import request from "../../services/request";
// import { ACTIONS } from "../../Routes/Api";
import { uploadMediaOns3 } from "../../utils/s3";
import session from "../../services/session";

const ActionerBrowseModal = (props) => {
    const { dispatch, action } = props;
    const { business_name } = session.getOrg();

    const [loader, setLoader] = useState(true);
    const [obj, setObj] = useState({ attachment: [{ custom_name: "" }] });
    const [extError, setExtError] = useState(false);
    const [currentfile, setCurrentFile] = useState("");

    const { selectedAction } = useSelector((state) => state?.action);

    const handledFieldChanges = (field, value, custom, index) => {
        let objCopy = { ...obj };
        if (!custom) objCopy[field] = value;

        switch (field) {
            case "attachment":
                if (objCopy[field][index]?.custom_name)
                    value.custom_name = objCopy[field][index]?.custom_name;
                objCopy[field][index] = value;
                break;
            case "addMoreAttachment":
                if (objCopy["attachment"].length < 5) objCopy["attachment"].push({});
                break;
            case "custom_name":
                // if(!objCopy?.custom_names)
                //     objCopy.custom_names=[{custom_name:value.value,index:value.index}]
                // else {
                //     let find = objCopy?.custom_names.findIndex((obj)=> obj.index === value.index)
                //     if(find !==-1)
                //         objCopy.custom_names[find]["custom_name"] = value.value
                // }
                objCopy["attachment"][value.index]["custom_name"] = value.value;

                break;
            default:
        }
        setObj(objCopy);
    };

    const toggleLoader = (index, flag) => {
        let objCopy = { ...obj };
        let selectedObj = objCopy.attachment[index];
        selectedObj.media_loader = flag;
        setObj(objCopy);
    };

    const handleFileSelection = async (value, index) => {
        try {
            const ext = value?.name?.split(/[#?]/)[0].split(".").pop().trim();
            if (ext === "avif" || ext === "tiff" || ext === "gif") {
                setExtError(true);
                return extError;
            }
            if (!value) return;
            setExtError(false);
            setCurrentFile(value);
            toggleLoader(index, true);
            setLoader(true);
            let uploadedObject = await uploadMediaOns3(business_name, value);
            setLoader(false);
            toggleLoader(index, false);
            if (!!uploadedObject?.Location) {
                value.path = uploadedObject?.Location;
                value.extension = uploadedObject?.extension;
                value.key = uploadedObject.key;
                handledFieldChanges("attachment", value, true, index);
            }
            /*let path = URL.createObjectURL(value)
                            value.path=path
                            value.showBlobImg = true*/
        } catch (e) {
            toggleLoader(false);
        }
    };

    const saveAttachment = async () => {
        obj?.attachment?.map((atch, index) => {
            if (!atch?.path) obj?.attachment?.splice(index, 1);
        });
        if (dispatch && action) dispatch(action({ ...obj }));
        setObj({ attachment: [{ custom_name: "" }] });

        /*try {
                    let data = preSaveFunction()
                    let result = await request.post(ACTIONS.ADD_ACTIONER_EVIDENCE,data,true,true)
                    if(result.status){
                        if(dispatch && action)
                            dispatch(action({...obj}))
                        dispatch(setSelectedAction({}))
                    }
                    setObj({attachment:[{custom_name:""}]})
        
                }catch (e) {}*/
    };

    const preSaveFunction = () => {
        obj?.attachment?.map((atch, index) => {
            if (!atch?.path) obj?.attachment?.splice(index, 1);
        });
        return setFormData(obj);
    };

    const setFormData = () => {
        let formData = new FormData();
        /*if(obj?.attachment?.length){
                    obj?.attachment?.map((file)=>{
                        formData.append("media", file)
                    })
                }*/
        formData.append("data", JSON.stringify({ ...obj, ...selectedAction }));
        return formData;
    };

    const handleCancel = () => {
        setObj({ attachment: [{ custom_name: "" }] });
    };

    useEffect(() => {
        if (!currentfile?.name) {
            setExtError(false);
        } else if (obj?.attachment?.length === 1 && !obj?.attachment[0].path) {
            setExtError(false);
        }
    }, [obj]);

    return (
        <div className="pro-browse-modal">
            <div
                className="modal fade"
                id="actionerBrowseModal"
                tabIndex="-1"
                aria-labelledby="actionerBrowseModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0">
                                    <img
                                        className="modal-img"
                                        src={BrowseMagnifier}
                                        alt="Browse Files"
                                    />
                                </div>
                                <div className="col-12 col-lg-8 p-5">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center-off text-lg-center mb-4">
                                        Let's add some evidence
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600">
                                        Choose the files you want to add to this Action.
                                    </h3>
                                    <p className="color-05 fs-12 fw-300">
                                        You can add as much evidence as you like - to add more files
                                        just click 'Add more evidence'.
                                    </p>
                                    <small className="text-danger">Max Evidence Limit is 5</small>
                                    {obj?.attachment?.map((obj, index) => (
                                        <div className="evidence-data mt-3" key={index}>
                                            <input
                                                type="text"
                                                className="form-control color-04 fs-14 mb-2"
                                                placeholder="Add an optional title..."
                                                value={obj?.custom_name}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "custom_name",
                                                        { value: e.target.value, index },
                                                        true
                                                    )
                                                }
                                            />
                                            <div className="">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputGroupFile02"
                                                    placeholder="Select Image"
                                                    aria-describedby="inputGroupFileAddon02"
                                                    onChange={(e) =>
                                                        handleFileSelection(e.target.files[0], index)
                                                    }
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                                <label
                                                    className="custom-file-button d-block mb-0 custom_text_overflow"
                                                    htmlFor="inputGroupFile02"
                                                >
                                                    {obj?.name || "Select Evidence..."}
                                                    {obj.media_loader && (
                                                        <a
                                                            className="btn-browse fs-12"
                                                            htmlFor="attach-modal-1"
                                                        >
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />{" "}
                                                            Loading
                                                        </a>
                                                    )}
                                                    {!obj.media_loader && (
                                                        <a className="btn-browse" htmlFor="attach-modal-1">
                                                            <i className="far fa-folder-open" /> Browse
                                                        </a>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                    {extError && (
                                        <small className="text-danger">
                                            File with extension .avif, .tiff and .gif are not
                                            compatible.
                                        </small>
                                    )}
                                    <NavLink
                                        to={"#"}
                                        className="add-more-evidence float-lg-right color-05 fs-14 fw-300 text-decoration-none mt-2"
                                        onClick={() => {
                                            handledFieldChanges("addMoreAttachment");
                                        }}
                                    >
                                        <i className="fas fa-plus-circle" /> Add more evidence
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{ marginRight: "35px" }}>
                            <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                data-target="#proBrowseModal"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn-export"
                                data-dismiss="modal"
                                data-target="#proBrowseModal"
                                disabled={loader}
                                onClick={saveAttachment}
                            >
                                Attach evidence
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionerBrowseModal;
