import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../store/actions/user";
import session from "../../services/session";
import utils from "../../utils";
import { APP_CONSTANTS } from "../../constants";
import { useEffect, useRef, useState } from "react";

const SettingBar = () => {
    const dispatch = useDispatch();
    const org = session.getOrg();
    const childRef = useRef();

    let url = window.location;

    const signOut = () => {
        dispatch(logOut());
    };
    const organization = JSON.parse(localStorage.getItem("org"));
    const multisite = JSON.parse(localStorage.getItem("multisite"));
    const user = JSON.parse(localStorage.getItem("user"));

    const orgData = useSelector((state) => state?.user?.org);

    const [parentHeight, setParentHeight] = useState(0);

    useEffect(() => {
        const updateParentHeight = () => {
            if (childRef?.current) {
                const height = childRef?.current?.clientHeight;
                setParentHeight(height);
            }
        };

        // Initial height calculation
        updateParentHeight();

        // Update height on window resize
        window.addEventListener("resize", updateParentHeight);

        return () => {
            // Remove the event listener on component unmount
            window.removeEventListener("resize", updateParentHeight);
        };
    }, []);

    const parentStyle = {
        height: parentHeight,
    };

    return (
        <div className="setting-bar" style={parentStyle}>
            <div className="container-fluid pr-0">
                <div className="row mx-0 align-items-center">
                    <div className="col-8 col-lg-10 px-0">
                        <div className="site-title d-flex align-items-center justify-content-between flex-column flex-lg-row">
                            <h2 className="fs-14 fw-600 mb-0">
                                {user?.userType === "superAdmin" &&
                                    url?.href.includes("super-admin") ? (
                                    <>Superadmin dashboard</>
                                ) : user?.loginType === "multi" && !organization?._id ? (
                                    <>
                                        {multisite?.multisiteName
                                            ? multisite?.multisiteName
                                            : "Azure"}{" "}
                                        - {"Multi-site"}
                                    </>
                                ) : (
                                    <>
                                        {org?.business_name} - {org?.site}
                                    </>
                                )}
                            </h2>
                            {orgData?.subscription?.status === "Due" ? (
                                <p
                                    className="mb-0 fs-14 fw-500 mr-3 text-danger px-2 my-1 bg-tr"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <i
                                        className="far fa-exclamation-triangle"
                                        style={{ color: "#dc3545" }}
                                    ></i>
                                    Your account is currently on hold, please contact Techni-K for
                                    assistance.
                                </p>
                            ) : orgData?.subscription?.status === "canceled" ||
                                orgData?.subscription?.status === "Canceled" ? (
                                <p
                                    className="mb-0 fs-14 fw-500 mr-3 text-danger px-2 my-1 bg-tr"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <i
                                        className="far fa-exclamation-triangle"
                                        style={{ color: "#dc3545" }}
                                    ></i>
                                    Your account is cancelled and set to 'read only'. Please
                                    contact Techni-K to resubscribe.
                                </p>
                            ) : null}
                        </div>
                    </div>
                    {/* Column End */}
                    <div className="col-4 col-lg-2 px-0">
                        <div
                            className="setting-icons d-flex justify-content-center align-items-center"
                            ref={childRef}
                        >
                            <ul className="list-unstyled mb-0">
                                {utils.checkPermissions(
                                    APP_CONSTANTS.PERMISSION_CONST.SETTING_BUTTON
                                ) &&
                                    organization?._id && (
                                        <li className="list-inline-item">
                                            <Link
                                                to={"system-settings"}
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Settings"
                                            >
                                                <i className="far fa-cog" />
                                            </Link>
                                        </li>
                                    )}
                                <li className="list-inline-item">
                                    <Link
                                        to={"my-profile"}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Profile"
                                    >
                                        <i className="far fa-user" />
                                    </Link>
                                </li>

                                <li className="list-inline-item">
                                    <Link
                                        to={"#"}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Logout"
                                    >
                                        <i className="far fa-sign-out-alt" onClick={signOut} />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* Column End */}
                </div>
            </div>
        </div>
    );
};

export default SettingBar;
