import React, { useState } from "react";
import ThumbsUp from "../../Assets/images/thumbs-up.png";
import request from "../../services/request";
import { GMP } from "../../Routes/Api";
import { getGMPQuestionsSuccess } from "../../store/actions/gmp";
import { useDispatch } from "react-redux";
import { APP_CONSTANTS } from "../../constants";

const CompleteItemModal = (props) => {
    const { id, h2, h3, action, gmpId } = props;
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        show: false,
        message: "",
    });
    const [success, setSuccess] = useState({
        show: false,
        message: "",
    });

    const completeItem = () => {
        setLoading(true);
        setDisable(true);
        request.put(`${GMP.COMPLETE_ITEM}/${gmpId}`, {}, true, false).then(
            (res) => {
                if (res) {
                    dispatch(getGMPQuestionsSuccess(res?.data));
                    setLoading(false);
                    setError({ show: false, message: "" });
                    setSuccess({ show: true, message: res?.message });
                    setTimeout(() => {
                        setSuccess({ show: false, message: "" });
                        action("complete", false);
                    }, APP_CONSTANTS.MODAL_SHOW_TIME);
                }
            },
            (error) => {
                setError({ show: true, message: error?.message });
                setLoading(false);
                setDisable(false);
                setTimeout(() => {
                    setError({ show: false, message: "" });
                }, APP_CONSTANTS.MODAL_SHOW_TIME);
            }
        );
    };

    return (
        <div className="save-modal">
            <div
                className="modal fade show"
                id={`${"completeModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="completeModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0 mb-4">
                                    <img
                                        src={ThumbsUp}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center-off text-lg-center mb-4">
                                        {h2}
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">{h3}</h3>
                                    {error?.show && (
                                        <div
                                            className="alert alert-danger mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {error?.message}
                                        </div>
                                    )}
                                    {success?.show && (
                                        <div
                                            className="alert alert-success mt-0"
                                            role="alert"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {success?.message}
                                        </div>
                                    )}
                                    <div className="float-right mt-4">
                                        <button
                                            className="clear_item mr-2 py-2 px-4 fs-16"
                                            onClick={() => action("complete", false)}
                                            disabled={disable}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn_clear_inspection py-2 px-4 fs-16"
                                            onClick={completeItem}
                                            disabled={disable}
                                        >
                                            Complete{" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default CompleteItemModal;
