import React from "react";
import ThumbsDown from "../../Assets/images/thumbs-down.png";

const ErrorModalGmpAnswer = (props) => {
    return (
        <div className="error-modal custom_modal_backdrop">
            <div
                className="modal fade show "
                id="errorModal"
                tabIndex="-1"
                aria-labelledby="errorModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0">
                                    <img src={ThumbsDown} alt="Export" width={"200px"} />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center-off text-lg-center mb-4">
                                        Oops... Error while saving!
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 text-md-center-off text-lg-center mb-4">
                                        {props?.h3}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorModalGmpAnswer;
