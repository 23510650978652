import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    editUserObject,
	resendPasswordAPI,
    toggleErrorModal,
    toggleSuccessModal,
	resendPasswordSuccessModal,
    resendPasswordErrorModal,
    updateUserAPi,
} from "../../store/actions/user";
import { changeTabState } from "../../store/actions/tabState";
import SaveModal from "../Modals/SaveModal";
import ErrorModal from "../Modals/ErrorModal";
import { APP_CONSTANTS } from "../../constants";
import utils from "../../utils";

const EditUser = () => {
    const dispatch = useDispatch();

    const { editUser, error } = useSelector((state) => state?.user);
    const showSuccessModal = useSelector((state) => state?.user?.success);
											
	const showResendPasswordSuccessModal = useSelector(
        (state) => state?.user?.resendPassword
    );

    const [validationError, setValidationError] = useState({});

    const handledFieldChanges = (field, value, custom, index) => {
        if (value && value !== "") {
            setValidationErrorObj(field, false);
        } else {
            setValidationErrorObj(field, true);
        }
        let userObjCopy = { ...editUser };
        if (!custom) {
            userObjCopy[field] = value;
            dispatch(editUserObject(userObjCopy));
            return;
        }
        switch (field) {
            case "disable_user":
                if (!userObjCopy[field] || userObjCopy[field] === "on")
                    userObjCopy[field] = true;
                else userObjCopy[field] = !userObjCopy[field];
                break;
            case "proactive_action":
                userObjCopy["permissions"]["default_user_roles"][field] =
                    !userObjCopy["permissions"]["default_user_roles"];
                break;
            case "corrective_action":
                userObjCopy["permissions"]["default_user_roles"][field] =
                    !userObjCopy["permissions"]["default_user_roles"][field];
                break;
            case "system_manager":
                userObjCopy["multisite_permissions"]["general"][field]["sites"][index] =
                {
                    ...userObjCopy["multisite_permissions"]["general"][field]["sites"][
                    index
                    ],
                    enable: value,
                };
                break;
            case "accounts":
                userObjCopy["multisite_permissions"]["general"][field]["sites"][index] =
                {
                    ...userObjCopy["multisite_permissions"]["general"][field]["sites"][
                    index
                    ],
                    enable: value,
                };
                break;
            case "root_cause_analysis":
                userObjCopy["multisite_permissions"]["actions"][field]["sites"][index] =
                {
                    ...userObjCopy["multisite_permissions"]["actions"][field]["sites"][
                    index
                    ],
                    enable: value,
                };
                break;
            case "verifier":
                userObjCopy["multisite_permissions"]["actions"][field]["sites"][index] =
                {
                    ...userObjCopy["multisite_permissions"]["actions"][field]["sites"][
                    index
                    ],
                    enable: value,
                };
                break;
            case "identifier":
                userObjCopy["multisite_permissions"]["actions"][field]["sites"][index] =
                {
                    ...userObjCopy["multisite_permissions"]["actions"][field]["sites"][
                    index
                    ],
                    enable: value,
                };
                break;
            case "account_manager":
                userObjCopy["multisite_permissions"]["system_audits"][field]["sites"][
                    index
                ] = {
                    ...userObjCopy["multisite_permissions"]["system_audits"][field][
                    "sites"
                    ][index],
                    enable: value,
                };
                break;
            case "auditor":
                userObjCopy["multisite_permissions"]["system_audits"][field]["sites"][
                    index
                ] = {
                    ...userObjCopy["multisite_permissions"]["system_audits"][field][
                    "sites"
                    ][index],
                    enable: value,
                };
                break;
            case "accountable_manager":
                userObjCopy["multisite_permissions"]["gmp_inspections"][field]["sites"][
                    index
                ] = {
                    ...userObjCopy["multisite_permissions"]["gmp_inspections"][field][
                    "sites"
                    ][index],
                    enable: value,
                };
                break;
            case "inspector":
                userObjCopy["multisite_permissions"]["gmp_inspections"][field]["sites"][
                    index
                ] = {
                    ...userObjCopy["multisite_permissions"]["gmp_inspections"][field][
                    "sites"
                    ][index],
                    enable: value,
                };
                break;
            default:
        }
        dispatch(editUserObject(userObjCopy));
    };

    const validateObject = () => {
        let errorFlag = false;
        setValidationError({});
        if (!editUser?.user_name) {
            setValidationErrorObj("user_name", true);
            errorFlag = true;
        }
        if (!editUser?.email) {
            setValidationErrorObj("email", true);
            errorFlag = true;
        }
        return errorFlag;
    };

    const setValidationErrorObj = (key, flag) => {
        setValidationError((old) => {
            return {
                ...old,
                [key]: flag,
            };
        });
    };

    const handleSave = () => {
        if (validateObject()) return;
        else {
            dispatch(updateUserAPi(editUser));
        }
    };

const resendPassword = () => {
        dispatch(resendPasswordAPI(editUser._id));
    };

    const handleCancel = () => {
        dispatch(changeTabState(1));
    };

    return (
        <section className="ce-user edit-multisite-user">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 mt-4">
                        <div className="form-group row pr-0 mr-0">
                            <label className="col-12 col-lg-2 col-form-label fs-14 fw-600 color-05">
                                Name
                            </label>
                            <div className="col-12 col-lg-10">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id=""
                                    placeholder="Name"
                                    value={editUser?.user_name}
                                    onChange={(e) =>
                                        handledFieldChanges("user_name", e.target.value)
                                    }
                                />
                                {validationError.user_name && (
                                    <small className="text-danger">
                                        please fill required field.
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="form-group row light-bg pr-0 mr-0">
                            <label className="col-2 col-lg-2 col-form-label fs-14 fw-600 color-05">
                                Email
                            </label>
                            <div className="col-12 col-lg-10">
                                <input
                                    required
                                    type="email"
                                    className="form-control form-control-sm"
                                    id=""
                                    placeholder="name@yourbusiness.com"
                                    value={editUser?.email}
                                    onChange={(e) =>
                                        handledFieldChanges(
                                            "email",
                                            e.target.value.toLocaleLowerCase()
                                        )
                                    }
                                />
                                {validationError.email && (
                                    <small className="text-danger">
                                        please fill required field.
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-6 mt-4 light-bg"
                        style={{ padding: "10px 15px" }}
                    >
                        <h2 className="fs-14 fw-600 color-05">Default user roles</h2>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-5">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck1"
                                        disabled={true}
                                        defaultChecked={
                                            editUser?.permissions?.default_user_roles.proactive_action
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck1"
                                    >
                                        Proactive actions
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-0">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheck2"
                                        disabled={true}
                                        defaultChecked={
                                            editUser?.permissions?.default_user_roles
                                                .corrective_action
                                        }
                                    />
                                    <label
                                        className="custom-control-label cursor fs-14 fw-400 color-04"
                                        htmlFor="customCheck2"
                                    >
                                        Corrective actions
                                    </label>
                                </div>
                            </div>
                        </div>
                        <small className="float-right mt-3 color-04 fs-11 fw-400 mr-3 mr-lg-0">
                            FYI - Everyone gets these default roles, so they can't be changed.
                        </small>
                    </div>
                </div>
                {/*  Row End  */}
                <div className="row mt-5 ce-select-options">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="fs-16 fw-600 color-04">General</h2>
                                <h4 className="fs-14 fw-600 color-04">System manager</h4>
                                {editUser?.multisite_permissions?.general?.system_manager?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`system_manager_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "system_manager",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`system_manager_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}

                                <h4 className="fs-14 fw-600 color-04 mt-4">Accounts</h4>

                                {editUser?.multisite_permissions?.general?.accounts?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`accounts_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "accounts",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`accounts_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0">
                                <h2 className="fs-16 fw-600 color-04">
                                    Non-conformance actions
                                </h2>
                                <h4 className="fs-14 fw-600 color-04 mt-4">Identifier</h4>
                                {editUser?.multisite_permissions?.actions?.identifier?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`identifier_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "identifier",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`identifier_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}

                                <h4 className="fs-14 fw-600 color-04 mt-4">
                                    Root cause analysis
                                </h4>
                                {editUser?.multisite_permissions?.actions?.root_cause_analysis?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`root_cause_analysis_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "root_cause_analysis",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`root_cause_analysis_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}

                                <h4 className="fs-14 fw-600 color-04 mt-4">Verifier</h4>
                                {editUser?.multisite_permissions?.actions?.verifier?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`verifier_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "verifier",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`verifier_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="fs-16 fw-600 color-04">System audits</h2>
                                <h4 className="fs-14 fw-600 color-04 mt-4">
                                    Accountable Manager
                                </h4>
                                {editUser?.multisite_permissions?.system_audits?.account_manager?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`account_manager_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "account_manager",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`account_manager_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}

                                <h4 className="fs-14 fw-600 color-04 mt-4">Auditor</h4>
                                {editUser?.multisite_permissions?.system_audits?.auditor?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`auditor_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "auditor",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`auditor_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0">
                                <h2 className="fs-16 fw-600 color-04">GMP inspections</h2>
                                <h4 className="fs-14 fw-600 color-04 mt-4">
                                    Accountable Manager
                                </h4>
                                {editUser?.multisite_permissions?.gmp_inspections?.accountable_manager?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`accountable_manager_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "accountable_manager",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`accountable_manager_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}

                                <h4 className="fs-14 fw-600 color-04 mt-4">Inspector</h4>

                                {editUser?.multisite_permissions?.gmp_inspections?.inspector?.sites?.map(
                                    (site, index) => (
                                        <div
                                            className="custom-control custom-checkbox mr-3"
                                            key={index}
                                        >
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={`inspector_${index}`}
                                                defaultChecked={site?.enable}
                                                disabled={editUser?.userType === "admin"}
                                                onChange={(e) =>
                                                    handledFieldChanges(
                                                        "inspector",
                                                        e.target.checked,
                                                        true,
                                                        index
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label cursor fs-14 fw-400 color-04 mr-3"
                                                htmlFor={`inspector_${index}`}
                                            >
                                                {site?.label}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Row End  */}
                <div className="row">
                    <div
                        className="col-12 col-lg-6 offset-lg-6 mt-4 light-bg"
                        style={{ padding: "10px 15px" }}
                    >
                        <h2 className="fs-14 fw-600 color-05">Disable this user</h2>
                        <div className="row">
                            <div className="col-6">
                                <p className="fs-11 fw-400 color-04">
                                    If you disable this user, they will no longer be able to login
                                    to audIT.app, receive notifications or be allocated actions.
                                </p>
                            </div>
                            <div className="col-6 col-lg-3 offset-lg-3">
                                <div className="custom-control custom-checkbox mr-3 mr-lg-0 text-right text-lg-left">
                                    <input
                                        className="custom-input"
                                        type="checkbox"
                                        id="customCheck11"
                                        defaultChecked={editUser?.disable_user}
                                        disabled={editUser?.userType === "admin"}
                                        onChange={(e) =>
                                            handledFieldChanges("disable_user", e.target.value, true)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {utils.checkSubscription() && (

                <div className="float-right mt-4">
                    <button className="btn-send mr-2" onClick={handleCancel}>
                        <i className="fal fa-window-close" /> Cancel 
                    </button>

 
                        {utils.checkPermissions("resendPassword") && (
                        <button
                            className="btn-save mr-1"
                            style={{ width: "60%" }}
                            onClick={resendPassword}
                        >
                            <i className="far fa-lock" /> Resend Password
                        </button>
                    )}
                    

                    <button className="btn-save mr-1" onClick={handleSave}>
                        <i className="far fa-save" /> Save
                    </button>
                </div>
            )}
            {showSuccessModal && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(toggleSuccessModal(false));
                            dispatch(changeTabState(1));
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.UPDATE_USER_MESSAGE.h2}
                        h3={APP_CONSTANTS.UPDATE_USER_MESSAGE.h3}
                        p={APP_CONSTANTS.UPDATE_USER_MESSAGE.p}
                    />
                </>
            )}
{showResendPasswordSuccessModal && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
                            dispatch(resendPasswordSuccessModal(false));
                            dispatch(changeTabState(1));
                        }}
                    ></div>
                    <SaveModal
                        h2={APP_CONSTANTS.RESEND_PASSWORD_MESSAGE.h2}
                        h3={APP_CONSTANTS.RESEND_PASSWORD_MESSAGE.h3}
                        p={APP_CONSTANTS.RESEND_PASSWORD_MESSAGE.p}
                    ></SaveModal>
                </>
            )}
            {error.show && (
                <>
                    <div
                        className="modal_backdrop"
                        onClick={() => {
							dispatch(resendPasswordErrorModal(error));
                            dispatch(toggleErrorModal({ show: false }));

                        }}
                    ></div>
                    <ErrorModal error={error} />
                </>
            )}
        </section>
    );
};

export default EditUser;
