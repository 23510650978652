import React, { useState } from "react";
import errorIcon from "../../Assets/images/error 1x.png";
import ThumbsUp from "../../Assets/images/thumbs-up.png";
import request from "../../services/request";
import { STANDARDS } from "../../Routes/Api";

const StandardModal = (props) => {
    const { standardData, toggleStandardModal, id } = props;
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        bool: false,
        message: "",
    });

    const updateAdditionalStandard = () => {
        setLoading(true);
        request.put(STANDARDS.ADD_REMOVE_STANDARDS, standardData, true, false).then(
            (res) => {
                if (res) {
                    setLoading(false);
                    toggleStandardModal(false);
                }
            },
            (error) => {
                setError({ bool: true, message: error?.message });
                setLoading(false);
            }
        );
    };

    return (
        <div className="save-modal">
            <div
                className="modal fade show"
                id={`${"clearModal"}-${id}`}
                tabIndex="-1"
                aria-labelledby="clearModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div
                                    className={
                                        standardData?.mode === "remove"
                                            ? "col-12 col-lg-4 text-center mb-4"
                                            : "col-12 col-lg-4 pl-0 mb-4"
                                    }
                                >
                                    <img
                                        src={standardData?.mode === "remove" ? errorIcon : ThumbsUp}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center-off text-lg-left mb-4">
                                        {standardData?.mode === "add"
                                            ? "Are you sure you want to add this additional standard?"
                                            : "Are you sure you want to remove this additional standard?"}
                                    </h2>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">
                                        {standardData?.mode === "add"
                                            ? "This will add this additoinal standard on your GMP inspection and system audits."
                                            : "This will remove this additoinal standard from your GMP inspection and system audits."}
                                    </h3>
                                    <h3 className="color-05 fs-18 fw-600 mb-4">
                                        Are you sure you want to proceed?
                                    </h3>

                                    {error?.bool && (
                                        <div className="alert alert-danger mt-0 mb-0" role="alert">
                                            {error?.message && error?.message}
                                        </div>
                                    )}

                                    <div className="float-right mt-4">
                                        <button
                                            className="cancel mr-2 py-2 px-4 fs-16"
                                            onClick={() => toggleStandardModal(false)}
                                        >
                                            No, Cancel
                                        </button>
                                        <button
                                            className="add_remove py-2 px-4 fs-16"
                                            onClick={updateAdditionalStandard}
                                            disabled={loading}
                                        >
                                            Yes, {standardData?.mode === "add" ? "Add" : "Remove"}{" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                >
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default StandardModal;
