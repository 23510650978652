import React, { useState } from "react";
import errorIcon from "../../Assets/images/error 1x.png";
import request from "../../services/request";
import { NC, PRO } from "../../Routes/Api";

const DeleteNcModal = (props) => {
    const { id, data, toggleDeleteNc } = props;

    const [loading, setLoading] = useState(false);

    const deleteItem = async () => {
        setLoading(true);
        if (data?.type === "NC") {
            try {
                let result = await request.delete(
                    NC.Delete_NC,
                    { id: data?._id },
                    true
                );
                if (result) {
                    setLoading(false);
                    // dispatch(getNcListAPi());
                    toggleDeleteNc(false);
                }
            } catch (e) { }
        } else {
            try {
                let result = await request.delete(
                    PRO.Delete_PRO,
                    { id: data?._id },
                    true
                );
                if (result) {
                    setLoading(false);
                    // dispatch(getNcListAPi());
                    toggleDeleteNc(false);
                }
            } catch (e) { }
        }
    };

    return (
        <div className="save-modal delete_nc">
            <div
                className="modal fade show"
                id={`${"deleteNc"}-${id}`}
                tabIndex="-1"
                aria-labelledby="deleteNcLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center mb-4">
                                    <img
                                        src={errorIcon}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center-off text-lg-center mb-4">
                                        Are you sure you want to delete this item?
                                    </h2>

                                    <div className="float-right mt-4">
                                        <button
                                            className="delete_item mr-2 py-2 px-4 fs-16"
                                            onClick={() => toggleDeleteNc(false)}
                                        >
                                            No, Cancel
                                        </button>
                                        <button
                                            className="delete_nc_success py-2 px-4 fs-16"
                                            onClick={deleteItem}
                                        >
                                            Yes, Delete
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default DeleteNcModal;
