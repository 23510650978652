import moment from "moment";
// import utils from "../utils";
import { generateSequence } from "../utils/referenceId";

// const checkPriority = (date) => {
//   if (!date) return <span>Due date not set</span>;
//   let { days } = utils.getRemainDays(
//     new Date().toISOString(),
//     new Date(date).toISOString()
//   );
//   if (days > 7 || days == 7) return "Green";
//   else if (days < 7 && days > 0) return "Amber";
//   else return "Red";
// };

const ncCountInRiskAssesment = (obj) => {
  if (obj?.isAutomatic) {
    return "Proactive";
  } else {
    return "Reactive";
  }
};

const checkPrioritySA = (status) => {
  if (status === "Draft" || status === "Due" || status === "Completed late") {
    return "Amber";
  } else if (status === "In progress" || status === "Completed") {
    return "Green";
  } else if (
    status === "Overdue" ||
    status === "Incomplete late" ||
    status === "Missed"
  ) {
    return "Red";
  }
};

const checkPriorityColors = (obj) => {
  switch (obj?.type) {
    case "GMP":
      if (obj?.status === "Draft" || obj?.status === "Due") {
        return "Amber";
      } else if (obj?.status === "In progress") {
        return "Green";
      } else if (obj?.status === "Overdue") {
        return "Red";
      }
      break;
    case "SA":
      if (obj?.status === "Due" || obj?.status === "Completed late") {
        return "Amber";
      } else if (obj?.status === "Completed" || obj?.status === "In progress") {
        return "Green";
      } else if (obj?.status === "Overdue" || obj?.status === "Missed") {
        return "Red";
      }
      break;
    case "CA":
      if (
        obj?.status === "Draft" ||
        obj?.status === "Completed and verified late"
      ) {
        return "Amber";
      } else if (
        obj?.status === "In progress" ||
        obj?.status === "Completed" ||
        obj?.status === "Verified"
      ) {
        return "Green";
      } else if (
        obj?.status === "Overdue" ||
        obj?.status === "Completed late" ||
        obj?.status === "Completed late and verified"
      ) {
        return "Red";
      }
      break;
    case "PA":
      if (
        obj?.status === "Draft" ||
        obj?.status === "Completed and verified late"
      ) {
        return "Amber";
      } else if (
        obj?.status === "In progress" ||
        obj?.status === "Completed" ||
        obj?.status === "Verified"
      ) {
        return "Green";
      } else if (
        obj?.status === "Overdue" ||
        obj?.status === "Completed late" ||
        obj?.status === "Completed late and verified"
      ) {
        return "Red";
      }
      break;
    case "PRO":
      if (obj?.status === "Draft") {
        return "Amber";
      } else if (obj?.status === "In progress" || obj?.status === "Completed") {
        return "Green";
      } else if (
        obj?.status === "Overdue" ||
        obj?.status === "Completed late"
      ) {
        return "Red";
      }
      break;
    case "NC":
      if (
        obj?.status === "Draft" ||
        obj?.status === "Sent" ||
        obj?.status === "Accepted" ||
        obj?.status === "Reassigned" ||
        obj?.status === "Closed late" ||
        obj?.status === "Delegated" ||
        obj?.status === "Completed late and verified late"
      ) {
        return "Amber";
      } else if (
        obj?.status === "In progress" ||
        obj?.status === "Verified late"
      ) {
        return "Green";
      } else if (obj?.status === "Overdue") {
        return "Red";
      } else if (obj?.status === "Closed") {
        return "Blue";
      }
      break;
    case "RCA":
      if (obj?.status === "Sent") {
        return "Amber";
      } else if (obj?.status === "In progress" || obj?.status === "Completed") {
        return "Green";
      } else if (
        obj?.status === "Overdue" ||
        obj?.status === "Completed late"
      ) {
        return "Red";
      }
      break;
    default:
      break;
  }
};

const gmpPriority = (status) => {
  if (
    status === "Draft" ||
    status === "Due" ||
    status === "Completed late" ||
    status === "Partly complete"
  ) {
    return "Amber";
  } else if (status === "In progress" || status === "Completed") {
    return "Green";
  } else if (
    status === "Overdue" ||
    status === "Partly completed late" ||
    status === "Missed"
  ) {
    return "Red";
  }
};

const handleStatus = (data) => {
  if (data[0]?.yes) {
    return "Yes";
  } else if (data[0]?.na) {
    return "N/A";
  } else if (data[0]?.no) {
    return "No";
  } else {
    return "null";
  }
};

const checkTodolistStatus = (obj) => {
  if (
    (obj?.type === "CA" || obj?.type === "PA" || obj?.type === "RCA") &&
    obj?.status === "In progress"
  ) {
    return "Awaiting verification";
  } else {
    return obj?.status;
  }
};

const checkSource = (type, data) => {
  if (type === "NC") {
    return `${data.source?.label} - ${data?.audit_text ||
      data?.gmp_text ||
      data?.custom_foreign_body ||
      data?.nc_product_type?.label ||
      data?.topic?.label ||
      ""
      }`;
  } else if (type === "CA" || type === "PA") {
    return `${data.nc.source?.label} - ${data?.nc.audit ||
      data?.nc.gmp_text ||
      data?.nc.custom_foreign_body ||
      data?.nc?.topic?.label
      }`;
  } else if (type === "PRO") {
    return data.source.label;
  } else if (type === "RCA") {
    return `${data.nc.source?.label} - ${data?.nc.audit ||
      data?.nc.gmp_text ||
      data?.nc.custom_foreign_body ||
      data?.nc?.topic?.label
      }`;
  } else if (type === "SA") {
    return `${data?.parentSA?.name}`;
  } else if (type === "GMP") {
    return `${data?.parentGmp?.gmpName}`;
  }
};

const checkSourceType = (obj) => {
  if (obj?.type === "NC") {
    return `${obj?.source?.label}`;
  } else if (obj?.type === "CA" || obj?.type === "PA" || obj?.type === "RCA") {
    return `${obj?.nc?.source?.label}`;
  } else if (obj?.type === "PRO") {
    return `${obj?.source?.label}`;
  } else if (obj?.type === "GMP") {
    return `${obj?.parentGmp?.gmpName}`;
  } else if (obj?.type === "SA") {
    return `${obj?.parentSA?.name}`;
  }
};

const calculateScore = (item) => {
  var score = 0;
  let answersCount = findCountofAnswers(item?.gmpQuestions);
  if (item?.status === "Missed") {
    score = score + 0;
  }
  if (item?.status === "Completed" || item?.status === "Completed late") {
    let cal =
      (answersCount?.yes / (item?.gmpQuestions?.length - answersCount?.na)) *
      100;
    score = score + cal ? cal : 0;
  }
  if (item?.status === "Partly complete") {
    let cal =
      answersCount?.yes +
      answersCount?.no +
      answersCount?.na -
      answersCount?.na;
    score = score + (answersCount?.yes / (cal ? cal : 0)) * 100;
  }
  let round = Math.round(score);
  return round || 0;
};

const findCountofAnswers = (questions) => {
  let count = {
    na: 0,
    yes: 0,
    no: 0,
  };
  questions?.map((item) => {
    if (item?.answers[0]?.na) {
      count.na = count.na + 1;
    }
    if (item?.answers[0]?.yes) {
      count.yes = count.yes + 1;
    }
    if (item?.answers[0]?.no) {
      count.no = count.no + 1;
    }
  });
  return count;
};

const userData = (data) => {
  if (data?.loginType === "multi" && data?.userType === "admin") {
    return `${data?.user_name + "\n" + "(multi-site admin)"}`;
  } else if (data?.loginType === "multi" && data?.userType === "regular") {
    return `${data?.user_name + "\n" + "(multi-site user)"}`;
  } else {
    return `${data?.user_name}`;
  }
};

const multisiteUserData = (data) => {
  if (data?.userType === "admin") {
    return `${data?.user_name + "\n" + "(multi-site admin)"}`;
  } else if (data?.userType === "regular") {
    return `${data?.user_name + "\n" + "(multi-site user)"}`;
  } else {
    return `${data?.user_name}`;
  }
};

export const APP_CONSTANTS = {
  GRADING_QUESTIONS: {
    Q: "Has this resulted in non-conforming material?",
    CHILD_QUESTION_1: {
      Q: "No",
      CHILD_QUESTION_1: {
        Q: "Is this system generally working?",
        CHILD_QUESTION_1: {
          Q: "No, a fundamental part is missing or not working.",
          A: "Major",
        },
        CHILD_QUESTION_2: {
          Q: "Yes, it's just small aspect that's not quite right.",
          A: "Minor",
        },
      },
    },
    CHILD_QUESTION_2: {
      Q: "Yes - it's not contaminated but it's out of specification.",
      CHILD_QUESTION_1: {
        Q: "How much of the batch is affected?",
        CHILD_QUESTION_1: {
          Q: "Small isolated quantity",
          A: "Minor",
        },
        CHILD_QUESTION_2: {
          Q: "A proportion or all of the batch",
          A: "Major",
        },
      },
    },
    CHILD_QUESTION_3: {
      Q: "Yes - it's contaminated",
      A: "Critical",
    },
  },

  GRADING_QUESTIONS_ARRAY: [
    {
      Q: "Has this resulted in non-conforming material?",
      checked: true,
      options: [
        {
          Q: "No",
          parentId: 0,
          id: 0,
          checked: false,
          child: [
            {
              Q: "Is this system generally working?",
              parentId: 0,
              id: 0,
              checked: false,
              options: [
                {
                  parentId: 0,
                  id: 0,
                  checked: false,
                  Q: "No, a fundamental part is missing or not working.",
                  A: "Major",
                },
                {
                  parentId: 0,
                  id: 1,
                  checked: false,
                  Q: "Yes, it's just small aspect that's not quite right.",
                  A: "Minor",
                },
              ],
            },
          ],
        },
        {
          Q: "Yes - it's not contaminated but it's out of specification.",
          parentId: 0,
          id: 1,
          checked: false,
          child: [
            {
              parentId: 1,
              id: 1,
              checked: false,
              Q: "How much of the batch is affected?",
              options: [
                {
                  parentId: 1,
                  id: 0,
                  checked: false,
                  Q: "Small isolated quantity",
                  A: "Minor",
                },
                {
                  parentId: 1,
                  id: 1,
                  checked: false,
                  Q: "A proportion or all of the batch",
                  A: "Major",
                },
              ],
            },
          ],
        },
        {
          parentId: 0,
          id: 2,
          checked: false,
          Q: "Yes - it's contaminated",
          A: "Critical",
        },
      ],
    },
  ],

  all_questions: [
    {
      q: "q 1",
      options: [
        {
          q: "q 1-1",
        },
      ],
    },
  ],

  KPI_SOURCE_OPTIONS: [
    { value: "External audit", label: "External audit" },
    { value: "GMP inspection", label: "GMP inspection" },
    { value: "System audit", label: "System audit" },
    { value: "Complaint", label: "Complaint" },
    { value: "Incident", label: "Incident" },
    { value: "Non-conforming product", label: "Non-conforming product" },
    {
      value: "Product Safety Point failure",
      label: "Product Safety Point failure",
    },
    { value: "Cleaning failure", label: "Cleaning failure" },
    {
      value: "Product testing and inspection failure",
      label: "Product testing and inspection failure",
    },
    {
      value: "Glass and hard plastic breakage",
      label: "Glass and hard plastic breakage",
    },
    { value: "Allergen spillage", label: "Allergen spillage" },
    { value: "Management review", label: "Management review" },
    { value: "Meeting actions", label: "Meeting actions" },
    { value: "Health and Safety", label: "Health and Safety" },
    { value: "Environmental", label: "Environmental" },
    { value: "Supplier monitoring", label: "Supplier monitoring" },
    { value: "Other", label: "Other" },
  ],

  SOURCE_OPTIONS: [
    { value: 1, label: "External audit" },
    // { value: 10, label: "Internal audit" },
    { value: 11, label: "GMP inspection" },
    { value: 12, label: "System audit" },
    { value: 2, label: "Complaint" },
    { value: 3, label: "Incident" },
    { value: 4, label: "Non-conforming product" },
    { value: 5, label: "Product Safety Point failure" },
    { value: 6, label: "Cleaning failure" },
    { value: 7, label: "Product testing and inspection failure" },
    { value: 8, label: "Glass and hard plastic breakage" },
    { value: 9, label: "Allergen spillage" },
  ],

  PRO_SOURCE_OPTIONS: [
    { value: 1, label: "Management review" },
    { value: 2, label: "Meeting actions" },
    { value: 3, label: "Health and Safety" },
    { value: 4, label: "Environmental" },
    { value: 5, label: "Supplier monitoring" },
    { value: 6, label: "Other" },
  ],

  COMPLAINT_TYPES: [
    { value: 1, label: "Legal" },
    { value: 2, label: "Quality" },
    { value: 3, label: "Foreign body" },
    { value: 4, label: "Microbial" },
  ],

  LEGAL_TYPES: [
    { value: 1, label: "Allergen" },
    { value: 2, label: "Coding" },
    { value: 3, label: "Count" },
    { value: 4, label: "Labelling" },
    { value: 5, label: "Nutritional" },
    { value: 6, label: "QUID" },
    { value: 7, label: "Volume" },
    { value: 8, label: "Weight" },
    { value: 9, label: "Other" },
  ],

  QUALITY_TYPES: [
    { value: 1, label: "Aroma" },
    { value: 2, label: "Carrier" },
    { value: 3, label: "Colour" },
    { value: 4, label: "Damaged packaging" },
    { value: 5, label: "Delivery fulfilment" },
    { value: 6, label: "Delivery timing" },
    { value: 7, label: "Flavour" },
    { value: 8, label: "Leaker" },
    { value: 9, label: "Minimum life on delivery" },
    { value: 10, label: "Mouldy" },
    { value: 11, label: "Off" },
    { value: 12, label: "Pallets" },
    { value: 13, label: "Sealing" },
    { value: 14, label: "Shape" },
    { value: 15, label: "Size" },
    { value: 16, label: "Tertiary packaging" },
    { value: 17, label: "Texture" },
    { value: 18, label: "Other" },
  ],

  FOREIGN_BODY_TYPES: [
    { value: 1, label: "Bone" },
    { value: 2, label: "Ceramic" },
    { value: 3, label: "Flexible plastic" },
    { value: 4, label: "Glass" },
    { value: 5, label: "Hair" },
    { value: 6, label: "Hard plastic" },
    { value: 7, label: "Metal" },
    { value: 8, label: "Organic matter (intrinsic)" },
    { value: 9, label: "Packaging" },
    { value: 10, label: "Paper or card" },
    { value: 11, label: "Pests" },
    { value: 12, label: "Rubber" },
    { value: 13, label: "Shell" },
    { value: 14, label: "String" },
    { value: 15, label: "Wood" },
    { value: 16, label: "Other" },
  ],

  MICROBIAL_TYPES: [
    { value: 1, label: "Delivery temperature" },
    { value: 2, label: "Sickness" },
    { value: 3, label: "Other" },
  ],

  NC_TYPE: [
    { value: 1, label: "Legal" },
    { value: 2, label: "Quality" },
    { value: 3, label: "Foreign body" },
  ],

  PRODUCT_TEST_AND_INSPECTION_FAILURE_YPE: [
    { value: 1, label: "Micro" },
    { value: 2, label: "Chemical" },
    { value: 3, label: "Quality" },
    { value: 4, label: "Sensory" },
  ],

  RAISED_OPTIONS: [
    { value: null, label: "Kassy Marsh" },
    { value: null, label: "Melanie Allen" },
    { value: null, label: "Steph O'Brien" },
    { value: null, label: "William Wonka" },
  ],

  TOPIC_OPTIONS: [
    {
      label: "01 Senior management commitment",
      value: "01 Senior management commitment",
    },
    { label: "02 Document management", value: "02 Document management" },
    { label: "03 Continuous improvement", value: "03 Continuous improvement" },
    { label: "04 Contingency planning", value: "04 Contingency planning" },
    { label: "05 Product safety plan", value: "05 Product safety plan" },
    { label: "06 Contamination control", value: "06 Contamination control" },
    { label: "07 Product development", value: "07 Product development" },
    { label: "08 Supplier management", value: "08 Supplier management" },
    { label: "09 Testing and inspection", value: "09 Testing and inspection" },
    { label: "10 Maintenance", value: "10 Maintenance" },
    { label: "11 Product defence", value: "11 Product defence" },
    {
      label: "12 Training and competency",
      value: "12 Training and competency",
    },
    { label: "13 Personnel", value: "13 Personnel" },
    { label: "14 Site standards", value: "14 Site standards" },
    { label: "15 Risk-based facilities", value: "15 Risk-based facilities" },
    { label: "16 Hygiene", value: "16 Hygiene" },
    { label: "17 Process control", value: "17 Process control" },
    {
      label: "18 Intake, storage and distribution",
      value: "18 Intake, storage and distribution",
    },
  ],

  CORE_STANDARDS: [
    { value: 1, label: "BRCGS Food Safety" },
    { value: 2, label: "BRCGS Packaging" },
    { value: 3, label: "BRCGS Agents and Brokers" },
    { value: 4, label: "BRCGS Storage and Distribution" },
  ],

  UPDATE_ORG_INFO: {
    h2: "Successfully Updated!",
    h3: "We’ve saved your changes to the system.",
    p: "Sit back, relax and we’ll automatically close this message in 5 seconds and you'll be magically transported back to your overview!",
  },

  CREATE_USER_MESSAGE: {
    h2: "New user created!",
    h3: "Your new user has been saved and we've sent them an email to welcome them aboard.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your overview!",
  },

  CREATE_MULTISITE_USER_MESSAGE: {
    h2: "New multi-site user created!",
    h3: "Your new user has been saved and we've sent them an email to welcome them aboard.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your overview!",
  },

  UPDATE_USER_MESSAGE: {
    h2: "User updated!",
    h3: "Good job! The user details have been successfully updated.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your overview!",
  },

  RESEND_PASSWORD_MESSAGE: {
    h2: "Password resend successful!",
    h3: "Good job! The resend password email has been sent successfully.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your overview!",
  },

  PRO_CREATE_MESSAGE: {
    h2: "Now that's PROactive!",
    h3: "Your proactive action has been saved into the system ready for your team to work on.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  PRO_SEND_MESSAGE: {
    h2: "Awesome, your team's been notified of your new PRO",
    h3: "Your proactive action has been saved into the system and your team's been emailed.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  PRO_COMPLETED: {
    h2: "Way to go!",
    h3: "We've updated/closed this PRO for you on the system.",
    p: "Sit back, relax and we’ll automatically close this message in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  RCA_SEND_MESSAGE: {
    h2: "Awesome, your team's been notified on completing RCA",
    h3: "Your root cause analysis has been saved into the system and your team's been emailed.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do list!",
  },

  NC_CREATE_MESSAGE: {
    h2: "Great job, your NC has been created.",
    h3: "Your NC has been saved into the system and is ready for your team to work on.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  NC_ACCEPT_MESSAGE: {
    h2: "It's all yours!",
    h3: "Thanks for accepting this NC. You can come back at any time and automatically create the required actions.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  NC_ACCEPT_CREATE_MESSAGE: {
    h2: "It's all yours!",
    h3: "Thanks for accepting this NC. We've automatically created you some actions.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  NC_REASSIGN_MESSAGE: {
    h2: "OK, all reassigned.",
    h3: "This NC has been reassigned and your colleague’s been notified that it’s ready for them to accept.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  NC_SEND_MESSAGE: {
    h2: "Great job, your NC has been saved and your team's been notified.",
    h3: "Your NC has been saved into the system and your team members have been emailed.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  CA_CREATE_MESSAGE: {
    h2: "Way to go!",
    h3: "Your corrective action for this non-conformance has been saved.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  CA_NOT_COMPLETED_MESSAGE_: {
    h2: "Oops! You can’t send this yet.",
    h3: "You need to have completed this action before we can send it to be verified.",
    // p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  PA_CREATE_MESSAGE: {
    h2: "Awesome job! ",
    h3: "Your preventive action for this non-conformance has been saved.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  RCA_CREATE_MESSAGE: {
    h2: "Good work!",
    h3: "Your root cause analysis has been saved for this NC.",
  },

  VERIFIER_ACTION_UPDATE_MESSAGE: {
    h2: "Good job verifying!",
    h3: "We've saved your changes to the system and the NC will get automatically updated.",
  },

  ACTION_SAVE_MESSAGE: {
    h2: "Awesome job! ",
    h3: "Your action for this non-conformance has been saved.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  ACTION_COMPLETE_Email: {
    h2: "Way to go!",
    h3: "Now you’ve completed this action we’ve let the verifier know it’s ready for them.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  ACTION_VERIFIER_Email: {
    h2: "Good job!",
    h3: "Awesome! You have verified the action!",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your to-do-list!",
  },

  CREATE_GMP_MESSAGE: {
    h2: "Great job, your GMP inspection has been saved.",
    h3: "That's it, your GMP inspection's all good to go.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to GMP inspections!",
  },

  SEND_GMP_MESSAGE: {
    h2: "Great job, your GMP inspection has been sent.",
    h3: "That's it, your GMP inspection's all good to go.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to GMP inspections!",
  },

  CREATE_ANSWER: {
    h2: "Great job, your answer has been saved.",
    h3: "That's it, your question's all good to go.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to the GMP Questions.",
  },

  GMP_ANSWER_ERROR: {
    h3: "Something went wrong while saving your answers.",
  },

  CREATE_AUDIT_MESSAGE: {
    h2: "Great job, your audit has been saved.",
    h3: "That's it, your audit is all good to go.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to Audit List!",
  },

  SEND_AUDIT_MESSAGE: {
    h2: "Great job, your audit has been sent.",
    h3: "That's it, your audit is all good to go.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to Audit List!",
  },

  CREATE_SA_ANSWER: {
    h2: "Great job, your answer has been saved.",
    h3: "That's it, your question's all good to go.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to the Audit List.",
  },

  SA_ANSWER_ERROR: {
    h3: "Something went wrong while saving your answers.",
  },

  SUCCESS_MULTISITE_MESSAGE: {
    h2: "New multi-site created!",
    h3: "We've emailed any users you created to let them know they can login.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your multi-site list!",
  },

  MULTISITE_UPDATED_MESSAGE: {
    h2: "Multi-site updated!",
    // h3: "We've emailed any users you created to let them know they can login.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your multi-site list!",
  },

  EDIT_USER_PERMISSION_MODAL: {
    h2: "Sorry, you can't edit this user",
    h3: "Please contact your multi-site admin to make changes to this user.",
  },

  FREQUENCY_MODAL: {
    h2: "How many days is that?",
    h3: "Here's a quick reference to the most common frequencies:",
    Calculate: [
      {
        label: "Weekly",
        value: "7 days",
      },
      {
        label: "Monthly",
        value: "30 days",
      },
      {
        label: "Quarterly",
        value: "90 days",
      },
      {
        label: "Twice yearly",
        value: "180 days",
      },
      {
        label: "Annually",
        value: "365 days",
      },
    ],
  },

  CLEAR_ITEM: {
    h2: "Are you sure you want to clear this item?",
    h3: "This will clear all the information on this item and can't be undone.",
    p: "Are you sure you want to proceed?",
  },

  COMPLETE_ITEM: {
    h2: "Is this item all complete?",
    h3: "If you're happy this item's complete hit the complete button, if you want to make any changes hit the edit button.",
  },

  ADD_ADDITIONAL_STANDARDS: {
    h2: "That's your additional standard added",
    h3: "We'll now include this additional standard in your GMP inspections and system audits.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your additional standards page!",
  },

  REPORT_SCHEDULER_SAVE: {
    h2: "Good job! your report's been saved",
    h3: "We've saved this report but it's not running yet. To make it active just click the schedule button.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your schedule reports page!",
  },

  REPORT_SCHEDULER_SCHEDULE: {
    h2: "Great work!",
    h2_sub: "Your report is now scheduled",
    h3: "Your next report will be emailed to you on",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your schedule reports page!",
  },

  REPORT_SCHEDULER_UPDATE: {
    h2: "Your changes have been saved!",
    h3: "Your scheduled report's been updated, you'll see these changes next time it get's emailed out.",
    p: "This message will automatically close in 5 seconds and you'll be magically transported back to your schedule reports page!",
  },

  DRAFT_SCHEDULER_UPDATE: {
    h2: "Are you sure you want to schedule this report?",
  },

  ACTION_SEND: { h2: "Action email has been sent" },

  PERMISSION_CONST: {
    MAKE_ACTION: "makeAction",
    CREATE_NC: "createNc",
    EDIT_NC: "editNC",
    DELEGATE_NC: "delegateNc",
    CREATE_PRO: "createPro",
    CREATE_CA: "createCa",
    CREATE_RCA: "createRca",
    CREATE_PA: "createPa",
    COMPLETE_PRO: "completePro",
    COMPLETE_CA: "completeCa",
    COMPLETE_RCA: "completeRca",
    COMPLETE_PA: "completePa",
    VIEW_NC_SUMMARY: "viewNcSummary",
    CREATE_USER: "createUser",
    ACCOUNTS: "accounts",
    ACTIONER_TAB: "actionerTab",
    VERIFIER_TAB: "verifierTab",
    SETTING_BUTTON: "settingButton",
    RESEND_PASSWORD: "resendPassword",
  },

  NC_EXPORT_HEADERS: [
    { label: "Item", key: "item" },
    { label: "Source type", key: "source" },
    { label: "Verifier", key: "verifier" },
    { label: "Auditor", key: "auditor" },
    { label: "Accountable Manager", key: "accountable_manager" },
    { label: "Raised by", key: "raised_by" },
    { label: "Date raised", key: "date_raised" },
    { label: "Due by", key: "due_date" },
    { label: "Topic", key: "topic" },
    { label: "Assign To", key: "accountable_manager" },
    { label: "Notes", key: "notes" },
    { label: "Grade", key: "grade" },
    { label: "Status", key: "status" },
    { label: "Priority", key: "priority" },
    { label: "Audit", key: "audit" },
    { label: "Complaint Type", key: "complaint_type" },
    { label: "Legal Type", key: "legal_type" },
    { label: "Custom Legal Type", key: "custom_legal" },
    { label: "Quality Type", key: "quality_type" },
    { label: "Custom Quality Type", key: "custom_quality" },
    { label: "Foreign Body Type", key: "foreign_body_type" },
    { label: "Custom Foreign Body Type", key: "custom_foreign_body" },
    { label: "Microbial Type", key: "micro_type" },
    { label: "Custom Microbial Type", key: "custom_micro" },
    { label: "Nc Product Type", key: "nc_product_type" },
    {
      label: "Product Test And Inspect Failure Type",
      key: "product_test_and_inspect_failure_type",
    },
  ],

  NCS_EXPORT_HEADERS: [
    { label: "Item", key: "item" },
    { label: "Source Type", key: "source" },
    { label: "Non-conformance type", key: "isAutomatic" },
    { label: "Verifier", key: "verifier" },
    { label: "Auditor", key: "auditor" },
    { label: "Accountable Manager", key: "accountable_manager" },
    { label: "Raised By", key: "raised_by" },
    { label: "Date Raised", key: "date_raised" },
    { label: "Due By", key: "due_date" },
    { label: "Topic", key: "topic" },
    { label: "Assign To", key: "assigned_to" },
    { label: "Notes", key: "notes" },
    { label: "Grade", key: "grade" },
    { label: "Status", key: "status" },
    { label: "Priority", key: "priority" },
    { label: "Audit", key: "audit" },
    { label: "Custom Foreign Body", key: "custom_foreign_body" },
    { label: "complaint Type", key: "complaint_type" },
    { label: "Foreign Body Type", key: "foreign_body_type" },
    { label: "Nc Product Type", key: "nc_product_type" },
    {
      label: "Product Test And Inspect Failure Type",
      key: "product_test_and_inspect_failure_type",
    },
  ],

  GMP_INSPECTIONS_HEADER: [
    { label: "Reference", key: "item" },
    { label: "Inspection", key: "gmpName" },
    { label: "Inspector", key: "inspectors" },
    { label: "Created Date", key: "createDate" },
    { label: "Due By", key: "dueDate" },
    { label: "Accountable Manager", key: "accountable_manager" },
    { label: "Verifier", key: "verifier" },
    { label: "Status", key: "status" },
    { label: "Score", key: "score" },
    { label: "GMP Type", key: "type" },
    { label: "Priority", key: "priority" },
  ],

  SA_HEADER: [
    { label: "Reference", key: "item" },
    { label: "Topic", key: "topic" },
    { label: "Accountable Manager", key: "accountable_manager" },
    { label: "Auditor", key: "lead_auditor" },
    { label: "Verifier", key: "verifier" },
    { label: "Status", key: "status" },
    { label: "Frequency", key: "frequency" },
    { label: "Last Audit", key: "last_audit" },
    { label: "Next Audit Due", key: "next_due" },
    { label: "Priority", key: "priority" },
  ],

  RISK_ASSESMENT_HEADER: [
    { label: "Reference", key: "item" },
    { label: "Topic", key: "topic" },
    { label: "Month", key: "month" },
    { label: "Risk Rating", key: "riskRating" },
    { label: "Critical NCs", key: "noOfCritical" },
    { label: "Major NCs", key: "noOfMajor" },
    { label: "Minor NCs", key: "noOfMinor" },
    { label: "Total NCs Points", key: "totalNcPoints" },
    { label: "RA Score", key: "assessmentScore" },
    { label: "Resulting Frequency", key: "frequency" },
  ],

  LOG_STANDARD_HEADER: [
    { label: "Standards", key: "label" },
    { label: "Ammendment Log", key: "logs" },
    { label: "Status", key: "enable" },
  ],

  ACTIONS_EXPORT_HEADER: [
    { label: "Item", key: "item" },
    { label: "Source Type", key: "source_type" },
    { label: "Date Raised", key: "date_raised" },
    { label: "Due By", key: "due_date" },
    { label: "Accountable Manager", key: "accountable_manager" },
    { label: "Verifier", key: "verifier" },
    { label: "Status", key: "status" },
    { label: "Notes", key: "notes" },
    { label: "Priority", key: "priority" },
  ],

  ACTION_EXPORT_HEADERS: [
    { label: "Item", key: "item" },
    { label: "Actioner", key: "actioner" },
    { label: "Due Date", key: "due_date" },
    { label: "Status", key: "status" },
    { label: "Corrective Action Notes", key: "corrective_action_notes" },
    { label: "Preventive Action Notes", key: "preventive_action_notes" },
    { label: "Actioner Notes", key: "actioner_notes" },
    { label: "Verifier Notes", key: "verifier_notes" },
  ],

  RCA_EXPORT_HEADERS: [
    { label: "Item", key: "item" },
    { label: "Due Date", key: "due_date" },
    { label: "Status", key: "status" },
    { label: "Root Cause Analysis Notes", key: "root_cause_analysis_notes" },
  ],

  SUBS_EXPORT_HEADERS: [
    { label: "Business name", key: "business_name" },
    { label: "Site", key: "site" },
    { label: "Subscription", key: "subs_name" },
    { label: "Status", key: "status" },
  ],

  MULTI_SITES_LIST_EXPORT_HEADERS: [
    { label: "Multi-site name", key: "multisiteName" },
    { label: "Sites", key: "sites" },
    { label: "Multi-site admin", key: "admins" },
  ],

  USER_EXPORT_HEADERS: [
    { label: "User", key: "user_name" },
    { label: "System manager", key: "system_manager" },
    { label: "Accounts", key: "account" },
    { label: "RCA", key: "rca" },
    { label: "Verifier", key: "verifier" },
    { label: "System audit accountable manager", key: "system_audit" },
    { label: "Auditor", key: "auditor" },
    { label: "GMP inspection accountable manager", key: "gmp" },
    { label: "Inspector", key: "inspector" },
    { label: "Disabled", key: "disable_user" },
  ],

  MULTISITE_TODOLIST_EXPORT_HEADERS: [
    { label: "Site", key: "site" },
    { label: "Item", key: "item" },
    { label: "Source type", key: "source_type" },
    { label: "Reference", key: "reference" },
    { label: "Date raised", key: "date_raised" },
    { label: "Due by", key: "due_by" },
    { label: "Notes", key: "notes" },
    { label: "Status", key: "status" },
    { label: "Grade", key: "grade" },
    { label: "Priority", key: "priority" },
  ],

  MULTISITE_USER_EXPORT_HEADERS: [
    { label: "User", key: "user_name" },
    { label: "Sites", key: "sites" },
    { label: "System manager", key: "system_manager" },
    { label: "Accounts", key: "accounts" },
    { label: "RCA", key: "rca" },
    { label: "Verifier", key: "verifier" },
    { label: "Identifier", key: "identifier" },
    { label: "System audit accountable manager", key: "system_audit_manager" },
    { label: "Auditor", key: "sys_auditor" },
    {
      label: "GMP inspection accountable manager",
      key: "gmp_inspection_manager",
    },
    { label: "Inspector", key: "sys_inspector" },
    { label: "Disabled", key: "disable" },
  ],

  GMP_QUESTIONS_HEADERS: [
    { label: "Topic Name", key: "topicName" },
    { label: "Subject ID display name", key: "subjectName" },
    { label: "Subtitle ID display name", key: "subtitleIdName" },
    { label: "Clause Reference", key: "standardRef" },
    { label: "GMP Question ID", key: "gmQID" },
    { label: "Question", key: "question" },
    { label: "Status", key: "status" },
  ],

  SA_QUESTIONS_HEADERS: [
    { label: "Subject ID display name", key: "subjectName" },
    { label: "Subtitle ID display name", key: "subtitleIdName" },
    { label: "Clause Reference", key: "standardRef" },
    { label: "SA Question ID", key: "saQID" },
    { label: "Question", key: "question" },
    { label: "Conforming", key: "status" },
    { label: "Conforming Notes", key: "cNotes" },
    { label: "Non-conforming Notes", key: "ncNotes" },
    { label: "Grade", key: "grade" },
  ],

  // REPEAT_NCS_EXPORT_HEADERS: [
  //   { label: "Topic", key: "topic" },
  //   { label: "Subject", key: "subjectName" },
  //   { label: "Subtitle", key: "subtitleIdName" },
  //   { label: "NC count", key: "nc_count" },
  //   { label: "NC references", key: "data" },
  // ],

  // SA_COMPLETION_EXPORT_HEADERS: (data) => {
  //   let header = [{ label: "", key: "topic" }];
  //   data?.map((item, index) => {
  //     header.push({ label: item, key: `date${index + 1}` });
  //   });

  //   return header;
  // },

  RCA_EXPORT_DATA_SET: (obj) => {
    if (obj?.type) obj.item = `${obj.type}-${obj.id}`;
    if (obj.actioner) obj.actioner = obj?.actioner?.label;
    if (obj.due_date)
      obj.due_date = moment(obj?.due_date).format(APP_CONSTANTS.DATE_FORMAT);
    return obj;
  },

  ACTION_EXPORT_DATA_SET: (obj) => {
    if (obj?.type) obj.item = `${obj.type}-${obj.id}`;
    if (obj.actioner) obj.actioner = obj?.actioner?.label;
    if (obj.due_date)
      obj.due_date = moment(obj?.due_date).format(APP_CONSTANTS.DATE_FORMAT);
    return obj;
  },

  SUBSCRIPTIONS_EXPORT_DATA_SET: (obj) => {
    if (obj?.business_name) obj.business_name = `${obj?.business_name}` || null;
    if (obj?.site) obj.site = `${obj?.site}` || null;
    if (obj?.subscription?.name)
      obj.subs_name = `${obj?.subscription?.name}` || null;
    if (obj?.subscription?.status)
      obj.status = `${obj?.subscription?.status}` || null;
    return obj;
  },

  MULTI_SITES_LIST_EXPORT_DATA_SET: (obj) => {
    if (obj?.multisiteName) obj.multisiteName = `${obj?.multisiteName}` || null;
    if (obj?.sites?.length) {
      let tempSites = [];
      obj?.sites?.forEach((item) => {
        tempSites.push(item?.label + ", ");
      });
      obj.sites = tempSites.join("");
      let lastIndex = obj.sites.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.sites =
          obj.sites.substring(0, lastIndex) +
          obj.sites.substring(lastIndex + 1);
      }
    }
    if (obj?.admins?.length) {
      let tempAdmins = [];
      obj?.admins?.forEach((item) => {
        tempAdmins.push(item?.user_name + " - " + item?.email + ", ");
      });
      obj.admins = tempAdmins.join("");
      let lastIndex = obj.admins.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.admins =
          obj.admins.substring(0, lastIndex) +
          obj.admins.substring(lastIndex + 1);
      }
    }
    return obj;
  },

  MULTISITE_TODOLIST_EXPORT_DATA_SET: (obj) => {
    if (obj?.org)
      obj.site = `${obj?.org?.business_name} - ${obj?.org?.site}` || null;
    if (obj?.type) obj.item = obj?.type || null;
    if (obj?.parentGmp || obj?.parentSA || obj?.source || obj?.nc?.source)
      obj.source_type = checkSourceType(obj);
    if (obj?.type) obj.reference = `${obj?.type}-${obj?.id}` || null;
    if (obj.date_raised || obj?.nc?.date_raised)
      obj.date_raised = moment(obj?.date_raised).format(
        APP_CONSTANTS.DATE_FORMAT
      );
    if (obj?.due_date)
      obj.due_by = moment(obj?.due_date).format(APP_CONSTANTS.DATE_FORMAT);
    if (
      obj?.non_conformance_notes ||
      obj?.action_notes ||
      obj?.corrective_action_notes ||
      obj?.preventive_action_notes ||
      obj?.notes ||
      obj?.root_cause_analysis_notes
    )
      obj.notes =
        obj?.non_conformance_notes ||
        obj?.action_notes ||
        obj?.corrective_action_notes ||
        obj?.preventive_action_notes ||
        obj?.notes ||
        obj?.root_cause_analysis_notes;
    if (obj?.status) {
      obj.priority = checkPriorityColors(obj);
    }
    if (obj?.status) obj.status = checkTodolistStatus(obj);
    if (obj?.grade) obj.grade = obj?.grade;

    return obj;
  },

  GMP_QUESTIONS_EXPORT_DATA_SET: (obj) => {
    if (obj?.topicName) obj.topicName = `${obj?.topicName}` || "null";
    if (obj?.subjectName && obj?.subjectName?.length)
      obj.subjectName = `${obj?.subjectName[0]}` || "null";
    if (obj?.subtitleIdName && obj?.subtitleIdName?.length)
      obj.subtitleIdName = `${obj?.subtitleIdName[0]}` || "null";
    if (obj?.standardRef && obj?.standardRef?.length)
      obj.standardRef = obj.standardRef?.join(", ") || "null";
    if (obj?.question) obj.question = `${obj?.question}` || "null";
    if (obj?.gmQID) obj.gmQID = `${obj?.gmQID}` || "null";
    if (obj?.answers[0]) obj.status = handleStatus(obj?.answers);
    return obj;
  },

  SA_QUESTIONS_EXPORT_DATA_SET: (obj) => {
    if (obj?.subjectName && obj?.subjectName?.length)
      obj.subjectName = `${obj?.subjectName[0]}` || "null";
    if (obj?.subtitleIdName && obj?.subtitleIdName?.length)
      obj.subtitleIdName = `${obj?.subtitleIdName[0]}` || "null";
    if (obj?.standardRef && obj?.standardRef?.length)
      obj.standardRef = obj.standardRef?.join(", ") || "null";
    if (obj?.question) obj.question = `${obj?.question}` || "null";
    if (obj?.saQID) obj.saQID = `${obj?.saQID}` || "null";
    if (obj?.answers[0]) obj.status = handleStatus(obj?.answers);
    if (obj?.cNotes) obj.cNotes = obj?.cNotes || "null";
    if (obj?.ncNotes) obj.ncNotes = obj?.ncNotes || "null";
    if (obj?.grade) obj.grade = obj?.grade || null;
    return obj;
  },

  NC_EXPORT_DATA_SET: (obj) => {
    if (obj?.type) {
      obj.item = `${obj.type}-${generateSequence(obj?.id)}`;
    }
    if (obj.source || obj?.nc?.source) {
      obj.source = checkSource(obj.type, obj)?.replace(/-\s*$/, "");
    }
    obj.isAutomatic = ncCountInRiskAssesment(obj);
    if (obj.actioner) {
      obj.actioner = `${obj.actioner?.label}`;
    }
    if (obj.verifier || obj?.nc?.verifier) {
      obj.verifier = obj?.verifier?.label || obj?.nc?.verifier?.label;
    }
    if (obj?.auditor) {
      obj.auditor = obj?.auditor?.label;
    }
    if (obj?.accountable_manager || obj?.nc?.accountable_manager) {
      obj.accountable_manager =
        obj?.accountable_manager?.label || obj?.nc?.accountable_manager?.label;
    }
    if (obj?.raised_by) {
      obj.raised_by = obj?.raised_by?.label;
    }
    if (obj?.date_raised) {
      obj.date_raised = moment(obj?.date_raised).format(
        APP_CONSTANTS.DATE_FORMAT
      );
    }
    if (obj?.due_date) {
      obj.due_date = moment(obj?.due_date).format(APP_CONSTANTS.DATE_FORMAT);
    }
    if (obj?.status) {
      obj.priority = checkPriorityColors(obj);
    }
    if (obj?.status) {
      obj.status = obj?.status;
    }
    if (obj?.topic) {
      obj.topic = obj?.topic?.label;
    }
    if (obj?.action_notes) {
      obj.notes = obj?.action_notes;
    }
    if (
      obj?.non_conformance_notes ||
      obj?.action_notes ||
      obj?.corrective_action_notes ||
      obj?.preventive_action_notes ||
      obj?.notes ||
      obj?.root_cause_analysis_notes
    ) {
      obj.notes = (
        obj?.non_conformance_notes ||
        obj?.action_notes ||
        obj?.corrective_action_notes ||
        obj?.preventive_action_notes ||
        obj?.notes ||
        obj?.root_cause_analysis_notes
      )
        .replace(/\s+/g, " ")
        .trim()
        .replace(/"/g, "");
    }
    if (obj?.complaint_type) {
      obj.complaint_type = obj?.complaint_type?.label;
    }
    if (obj?.foreign_body_type) {
      obj.foreign_body_type = obj?.foreign_body_type?.label;
    }
    if (obj?.nc_product_type) {
      obj.nc_product_type = obj?.nc_product_type?.label;
    }
    if (obj?.product_test_and_inspect_failure_type) {
      obj.product_test_and_inspect_failure_type =
        obj?.product_test_and_inspect_failure_type?.label;
    }
    if (obj?.legal_type) {
      obj.legal_type = obj.legal_type?.label;
    }
    if (obj?.custom_legal) {
      obj.custom_legal = obj.custom_legal;
    }
    if (obj?.quality_type) {
      obj.quality_type = obj.quality_type?.label;
    }
    if (obj?.custom_quality) {
      obj.custom_quality = obj?.custom_quality;
    }
    if (obj?.custom_foreign_body) {
      obj.custom_foreign_body = obj?.custom_foreign_body;
    }
    if (obj?.micro_type) {
      obj.micro_type = obj?.micro_type?.label;
    }
    if (obj?.custom_micro) {
      obj.custom_micro = obj?.custom_micro;
    }
    return obj;
  },

  ACTIONS_EXPORT_DATA_SET: (obj) => {
    if (obj?.type) {
      obj.item = `${obj.type}-${generateSequence(obj?.id)}`;
    }
    if (obj?.source?.label || obj?.nc?.source?.label) {
      obj.source_type = obj?.source?.label
        ? obj?.source?.label
        : obj?.nc?.source?.label;
    }
    if (obj?.createdAt) {
      obj.date_raised = moment(obj?.createdAt).format(
        APP_CONSTANTS?.DATE_FORMAT
      );
    }
    if (obj?.due_date) {
      obj.due_date = moment(obj?.due_date).format(APP_CONSTANTS?.DATE_FORMAT);
    }
    if (obj?.nc?.accountable_manager?.label) {
      obj.accountable_manager = obj?.nc?.accountable_manager?.label;
    }
    if (obj?.nc?.verifier?.label) {
      obj.verifier = obj?.nc?.verifier?.label;
    }
    if (obj?.status) {
      obj.status = obj?.status;
    }
    if (
      obj?.preventive_action_notes ||
      obj?.corrective_action_notes ||
      obj?.action_notes ||
      obj?.root_cause_analysis_notes
    ) {
      obj.notes =
        obj?.preventive_action_notes ||
        obj?.corrective_action_notes ||
        obj?.action_notes ||
        obj?.root_cause_analysis_notes;
    }
    if (obj?.status) {
      obj.priority = checkPriorityColors(obj);
    }
    return obj;
  },

  GMP_EXPORT_DATA_SET: (obj) => {
    if (obj?.type) obj.item = `${obj?.type}-${generateSequence(obj?.id)}`;
    if (obj?.parentGmp) obj.gmpName = obj?.parentGmp?.gmpName;
    if (obj?.verifier) obj.verifier = obj?.verifier?.label;
    if (obj?.accountable_manager)
      obj.accountable_manager = obj?.accountable_manager?.label;
    if (obj?.raised_by) obj.raised_by = obj?.raised_by?.label;
    if (obj?.inspectors?.length) {
      let tempArr = [];
      obj?.inspectors?.forEach((item) => {
        tempArr.push(item?.label + ", ");
      });
      obj.inspectors = tempArr.join("");
      let lastIndex = obj?.inspectors.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.inspectors =
          obj?.inspectors.substring(0, lastIndex) +
          obj?.inspectors.substring(lastIndex + 1);
      }
    }
    if (obj?.date_raised)
      obj.createDate = moment(obj?.date_raised).format(
        APP_CONSTANTS.DATE_FORMAT
      );
    if (obj?.due_date)
      obj.dueDate = moment(obj?.due_date).format(APP_CONSTANTS.DATE_FORMAT);

    obj.score = `${calculateScore(obj)}%`;
    obj.type = obj?.isAutomatic ? "Automatic" : "Manual";

    if (obj?.status) {
      obj.priority = gmpPriority(obj?.status);
    }
    return obj;
  },

  SA_EXPORT_DATA_SET: (obj) => {
    if (obj?.type) obj.item = `${obj?.type}-${generateSequence(obj?.id)}`;
    if (obj?.parentSA?.name) obj.topic = obj?.parentSA?.name;
    if (obj?.accountable_manager)
      obj.accountable_manager = obj?.accountable_manager?.label;
    if (obj?.lead_auditor) obj.lead_auditor = obj?.lead_auditor?.label;
    if (obj?.verifier) obj.verifier = obj?.verifier?.label;
    if (obj?.status) obj.status = obj?.status;
    // if (obj?.parentSA?.frequency)
    obj.frequency = obj?.isAutomatic
      ? parseInt(obj?.frequency / 30) === 12
        ? "Annual"
        : `${parseInt(obj?.frequency / 30)}-monthly`
      : "Manually created";

    if (obj?.status === "Completed" || obj?.status === "Completed late")
      obj.last_audit = moment(obj?.lastActDate).format(
        APP_CONSTANTS.DATE_FORMAT
      );
    if (obj?.parentSA?.nextAudit)
      obj.next_due = obj?.isAutomatic
        ? moment(obj?.next_audit).format(APP_CONSTANTS.DATE_FORMAT)
        : "Manually created";
    if (obj?.status) {
      obj.priority = checkPrioritySA(obj?.status);
    }
    return obj;
  },

  RISK_EXPORT_DATA_SET: (obj) => {
    if (obj?.type) obj.item = `${obj?.type}-${generateSequence(obj?.id)}`;
    if (obj?.topic) obj.topic = obj?.topic?.label;
    if (obj?.month) obj.month = moment(obj?.month).format("MMM YYYY");
    if (obj?.riskRating) obj.riskRating = obj?.riskRating;
    if (obj?.noOfCritical) obj.noOfCritical = obj?.noOfCritical;
    if (obj?.noOfMajor) obj.noOfMajor = obj?.noOfMajor;
    if (obj?.noOfMinor) obj.noOfMinor = obj?.noOfMinor;

    obj.totalNcPoints = obj?.ncPoints;
    if (obj?.assessmentScore) obj.assessmentScore = obj?.assessmentScore;
    if (obj?.frequency) {
      obj.frequency =
        parseInt(obj?.frequency / 30) === 12
          ? "Annual"
          : `${parseInt(obj?.frequency / 30)}-monthly`;
    }
    return obj;
  },

  LOG_STANDARD_EXPORT_DATA_SET: (obj) => {
    if (obj?.label) obj.label = obj?.label;

    let respArr = [];
    obj?.logs.map((item) => {
      respArr.push("Date: " + moment(item?.date).format("Do MMM YYYY"));
      respArr.push("Ammendment: " + item?.ammendment);
      respArr.push("Description: " + (item?.description || "-") + "\n");
    });

    if (obj?.logs) obj.logs = respArr.join("\n");
    obj.enable = obj?.enable ? "ON" : "OFF";

    return obj;
  },

  ORG_EXPORT_DATA_SET: (obj) => {
    if (obj?.subscription?.name) obj.subs_name = obj?.subscription?.name;
    if (obj?.subscription?.status) obj.status = obj?.subscription?.status;
    return obj;
  },

  USER_EXPORT_DATA_SET: (obj) => {
    if (obj?.user_name) obj.user_name = userData(obj);
    if (obj?.permissions?.general?.system_manager) obj.system_manager = true;
    else obj.system_manager = false;
    if (obj?.permissions?.general?.accounts) obj.account = true;
    else obj.account = false;
    if (obj?.permissions?.actions?.root_cause_analysis) obj.rca = true;
    else obj.rca = false;
    if (obj?.permissions?.actions?.verifier) obj.verifier = true;
    else obj.verifier = false;
    if (obj?.permissions?.system_audits?.account_manager)
      obj.system_audit = true;
    else obj.system_audit = false;
    if (obj?.permissions?.system_audits?.auditor) obj.auditor = true;
    else obj.auditor = false;
    if (obj?.permissions?.gmp_inspections?.accountable_manager) obj.gmp = true;
    else obj.gmp = false;
    if (obj?.permissions?.gmp_inspections?.inspector) obj.inspector = true;
    else obj.inspector = false;
    return obj;
  },

  MULTISITE_USER_EXPORT_DATA_SET: (obj) => {
    if (obj?.user_name) obj.user_name = multisiteUserData(obj) || null;
    if (obj?.multisite_permissions?.general?.system_manager?.sites?.length) {
      let tempSites = [];
      obj?.multisite_permissions?.general?.system_manager?.sites?.forEach(
        (item) => {
          tempSites.push(item?.label + ", ");
        }
      );
      obj.sites = tempSites.join("");
      let lastIndex = obj.sites.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.sites =
          obj.sites.substring(0, lastIndex) +
          obj.sites.substring(lastIndex + 1);
      }
    }

    if (obj?.multisite_permissions?.general?.system_manager?.sites?.length) {
      let tempSystemManager = [];
      obj?.multisite_permissions?.general?.system_manager?.sites?.forEach(
        (item) => {
          tempSystemManager.push(item?.enable + ", ");
        }
      );
      obj.system_manager = tempSystemManager.join("");
      let lastIndex = obj.system_manager.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.system_manager =
          obj.system_manager.substring(0, lastIndex) +
          obj.system_manager.substring(lastIndex + 1);
      }
    }

    if (obj?.multisite_permissions?.general?.accounts?.sites?.length) {
      let tempAccounts = [];
      obj?.multisite_permissions?.general?.accounts?.sites?.forEach((item) => {
        tempAccounts.push(item?.enable + ", ");
      });
      obj.accounts = tempAccounts.join("");
      let lastIndex = obj.accounts.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.accounts =
          obj.accounts.substring(0, lastIndex) +
          obj.accounts.substring(lastIndex + 1);
      }
    }

    if (
      obj?.multisite_permissions?.actions?.root_cause_analysis?.sites?.length
    ) {
      let tempRca = [];
      obj?.multisite_permissions?.actions?.root_cause_analysis?.sites?.forEach(
        (item) => {
          tempRca.push(item?.enable + ", ");
        }
      );
      obj.rca = tempRca.join("");
      let lastIndex = obj.rca.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.rca =
          obj.rca.substring(0, lastIndex) + obj.rca.substring(lastIndex + 1);
      }
    }

    if (obj?.multisite_permissions?.actions?.verifier?.sites?.length) {
      let tempVeirifier = [];
      obj?.multisite_permissions?.actions?.verifier?.sites?.forEach((item) => {
        tempVeirifier.push(item?.enable + ", ");
      });
      obj.verifier = tempVeirifier.join("");
      let lastIndex = obj.verifier.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.verifier =
          obj.verifier.substring(0, lastIndex) +
          obj.verifier.substring(lastIndex + 1);
      }
    }

    if (obj?.multisite_permissions?.actions?.identifier?.sites?.length) {
      let tempIdentifier = [];
      obj?.multisite_permissions?.actions?.identifier?.sites?.forEach(
        (item) => {
          tempIdentifier.push(item?.enable + ", ");
        }
      );
      obj.identifier = tempIdentifier.join("");
      let lastIndex = obj.identifier.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.identifier =
          obj.identifier.substring(0, lastIndex) +
          obj.identifier.substring(lastIndex + 1);
      }
    }

    if (
      obj?.multisite_permissions?.system_audits?.account_manager?.sites?.length
    ) {
      let tempSystemAuditsManager = [];
      obj?.multisite_permissions?.system_audits?.account_manager?.sites?.forEach(
        (item) => {
          tempSystemAuditsManager.push(item?.enable + ", ");
        }
      );
      obj.system_audit_manager = tempSystemAuditsManager.join("");
      let lastIndex = obj.system_audit_manager.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.system_audit_manager =
          obj.system_audit_manager.substring(0, lastIndex) +
          obj.system_audit_manager.substring(lastIndex + 1);
      }
    }

    if (obj?.multisite_permissions?.system_audits?.auditor?.sites?.length) {
      let tempAuditor = [];
      obj?.multisite_permissions?.system_audits?.auditor?.sites?.forEach(
        (item) => {
          tempAuditor.push(item?.enable + ", ");
        }
      );
      obj.sys_auditor = tempAuditor.join("");
      let lastIndex = obj.sys_auditor.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.sys_auditor =
          obj.sys_auditor.substring(0, lastIndex) +
          obj.sys_auditor.substring(lastIndex + 1);
      }
    }

    if (
      obj?.multisite_permissions?.gmp_inspections?.accountable_manager?.sites
        ?.length
    ) {
      let tempGmpInspectionManager = [];
      obj?.multisite_permissions?.gmp_inspections?.accountable_manager?.sites?.forEach(
        (item) => {
          tempGmpInspectionManager.push(item?.enable + ", ");
        }
      );
      obj.gmp_inspection_manager = tempGmpInspectionManager.join("");
      let lastIndex = obj.gmp_inspection_manager.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.gmp_inspection_manager =
          obj.gmp_inspection_manager.substring(0, lastIndex) +
          obj.gmp_inspection_manager.substring(lastIndex + 1);
      }
    }

    if (obj?.multisite_permissions?.gmp_inspections?.inspector?.sites?.length) {
      let tempInspector = [];
      obj?.multisite_permissions?.gmp_inspections?.inspector?.sites?.forEach(
        (item) => {
          tempInspector.push(item?.enable + ", ");
        }
      );
      obj.sys_inspector = tempInspector.join("");
      let lastIndex = obj.sys_inspector.lastIndexOf(",");
      if (lastIndex !== -1) {
        obj.sys_inspector =
          obj.sys_inspector.substring(0, lastIndex) +
          obj.sys_inspector.substring(lastIndex + 1);
      }
    }

    if (obj?.disable_user) obj.disable = obj?.disable_user;
    return obj;
  },

  GET_N_DAYS_NEXT_DATE: (days) =>
    moment().add(days, "day").format("YYYY-MM-DD"),
  CA_STATUS: [{ label: "Draft" }, { label: "Open" }],
  STATUS: {
    DRAFT: "Draft",
    OPEN: "Open",
  },

  DATE_FORMAT: "DD/MM/YYYY",
  DATE_FORMAT_1: "YYYY-MM-DD",
  MODAL_SHOW_TIME: 5000,
  ACCESS_TOKEN_SUCCESS_MESSAGE: "Access token has been sent to your email",

  mainReport: [
    {
      label: "Non-conformances",
      value: "NC",
      isSelected: false,
    },
    {
      label: "Actions",
      value: "AC",
      isSelected: false,
      child: [
        {
          label: "Corrective actions",
          value: "CA",
          isSelected: false,
        },
        {
          label: "Preventive actions",
          value: "PA",
          isSelected: false,
        },
        {
          label: "Proactive actions",
          value: "PRO",
          isSelected: false,
        },
        {
          label: "Root cause analysis",
          value: "RCA",
          isSelected: false,
        },
      ],
    },
  ],

  grade: [
    { label: "Critical", value: "Critical" },
    { label: "Major", value: "Major" },
    { label: "Minor", value: "Minor" },
  ],
};
