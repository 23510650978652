import React, { useState } from "react";
import ThumbsUp from "../../Assets/images/thumbs-up.png";
import { useDispatch } from "react-redux";
import { sendProApi } from "../../store/actions/pro";

const SendProModal = (props) => {
    const { toggle, id, proObj } = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const sendItem = () => {
        setLoading(true);
        dispatch(sendProApi({ ...proObj, status: "In progress" }, "proSend"));
    };

    return (
        <div className="save-modal send_gmp_to_inspectors">
            <div
                className="modal fade show"
                id={`${id}`}
                tabIndex="-1"
                aria-labelledby={`${id} + Label`}
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-wd modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 pl-0 mb-4">
                                    <img
                                        src={ThumbsUp}
                                        alt="Save Notification Pro"
                                        width={"200px"}
                                    />
                                </div>
                                <div className="col-12 col-lg-8 px-4">
                                    <h2 className="color-03 fs-24 fw-600 text-md-center-off text-lg-center mb-5">
                                        Do you really want to send this PRO?
                                    </h2>

                                    <div className="float-right mt-4">
                                        <button
                                            className="clear_item mr-2 py-2 px-4 fs-16"
                                            onClick={() => toggle(false)}
                                            disabled={loading}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn_clear_inspection py-2 px-4 fs-16"
                                            onClick={sendItem}
                                            disabled={loading}
                                        >
                                            Send{" "}
                                            {loading && (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    style={{ marginBottom: "2px" }}
                                                ></div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default SendProModal;
